@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/eot/IRANSansWeb_Bold.eot");
  src: url("./fonts/eot/IRANSansWeb_Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/woff2/IRANSansWeb_Bold.woff2") format("woff2"),
    url("./fonts/woff/IRANSansWeb_Bold.woff") format("woff"),
    url("./fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/eot/IRANSansWeb_Medium.eot");
  src: url("./fonts/eot/IRANSansWeb_Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/woff2/IRANSansWeb_Medium.woff2") format("woff2"),
    url("./fonts/woff/IRANSansWeb_Medium.woff") format("woff"),
    url("./fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/eot/IRANSansWeb_Light.eot");
  src: url("./fonts/eot/IRANSansWeb_Light.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"),
    url("./fonts/woff/IRANSansWeb_Light.woff") format("woff"),
    url("./fonts/ttf/IRANSansWeb_Light.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/eot/IRANSansWeb_UltraLight.eot");
  src: url("./fonts/eot/IRANSansWeb_UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"),
    url("./fonts/woff/IRANSansWeb_UltraLight.woff") format("woff"),
    url("./fonts/ttf/IRANSansWeb_UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/eot/IRANSansWeb.eot");
  src: url("./fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"),
    url("./fonts/woff2/IRANSansWeb.woff2") format("woff2"),
    url("./fonts/woff/IRANSansWeb.woff") format("woff"),
    url("./fonts/ttf/IRANSansWeb.ttf") format("truetype");
}
[dir="rtl"] * {
  font-family: IRANSans !important;
}
[dir="rtl"] body {
  font-family: IRANSans !important;
}
/* for showMenuAlarm */

#showMenuAlarm:last-child {
  max-width: 32% !important;
  min-width: 470px !important;
}
@media screen and (max-width: 576px) {
  #showMenuAlarm:last-child {
    max-width: 32% !important;
    min-width: 100% !important;
  }
}
[dir="rtl"] #showMenuAlarm:last-child {
  margin-right: auto !important;
}
#CustomCSSFiled:last-child {
  flex: 2;
}
