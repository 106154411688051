.MuiSelect-multiple {
  padding: 9px !important;
}

/* start emoji__input */
#emoji__input {
  width: 100%;
}
#emoji__input > div {
  width: 100%;
}
#emoji__input > div > em-emoji-picker {
  width: 100%;
}
@media screen and (max-width: 767px) {
  #emoji__input {
    bottom: 0px !important;
  }
}
/* end emoji__input */
/* start ticket */

[dir="rtl"] #ticket__img,
[dir="rtl"] #ticket__title {
  order: 1;
}
[dir="rtl"] #ticket__img div {
  margin-left: 1rem !important;
}
[dir="rtl"] #ticket__text {
  order: -1;
  justify-content: flex-start;
}
[dir="rtl"] #ticket__meas {
  width: 100%;
  margin-left: 1rem;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}
[dir="rtl"] #ticket__close-chat {
  transform: rotateY(180deg);
}
/* end ticket */
/* start closed__title */
#closed__title > span {
  padding: 0.2rem 0.4rem;
  width: max-content;
  left: 23px !important;
}

/* end closed__title */
/* start notification__count */
#notification__count > span {
  top: 5px !important;
  left: 1px;
}
/* end notification__count */
/* start isOnline__user */
.isOnline__user {
  position: fixed;
  width: 100%;
  height: 5px;
  transition: all 400ms ease;
  bottom: 0;
  z-index: 20;
}
/* end isOnline__user */
/* start loadingRoutes */
.loadingRoutes {
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  width: 100vw;
  height: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999999999;
}
/* end loadingRoutes */

/* start .muirtl-uls1yk-MuiDivider-root */
.muirtl-uls1yk-MuiDivider-root {
  margin-right: 0 !important;
}
.muirtl-fydjzv {
  background-color: #fff !important;
}
/* end .muirtl-uls1yk-MuiDivider-root */
