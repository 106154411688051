@media screen and (min-width: 600px) {
  [dir="rtl"] .Full__show-brand {
    margin-right: 14rem;
  }
  [dir="ltr"] .Full__show-brand {
    margin-left: 14rem;
  }
  [dir="rtl"] .imperfect__show-berand {
    margin-right: 3rem;
  }
  [dir="ltr"] .imperfect__show-berand {
    margin-left: 3rem;
  }
}
/*  start mainListItem */
.count__message-item {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  position: absolute;
  font-size: 0.8rem;
  background-color: #325fd7;
  border-radius: 50%;
  bottom: 2px;
}
[dir="rtl"] .count__message-item {
  left: -94%;
}
[dir="ltr"] .count__message-item {
  right: -94%;
}
/*  end mainListItem */
/* start item__links */
.item__links {
  transition: all 300ms ease !important;
}
.item__links > * {
  pointer-events: none !important;
}
.item__links--selected {
  background-color: #2576d2 !important;
  color: #fff !important;
}
.item__links--selected > div > svg,
.item__links--selected > div > span > svg {
  color: #fff !important;
}
/* end item__links */
